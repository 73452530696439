import PropTypes from 'prop-types';
import { PureComponent } from 'react';

import IfStore from 'Component/IfStore';
import Overlay from 'Component/Overlay';
import Popup from 'Component/Popup';
import { CATEGORY_ID_ANGOLO_DELLE_OCCASIONI, ID_POPUP } from 'Route/AngoloDelleOccasioni/AngoloDelleOccasioni.config';
import TextPlaceholder from 'SourceComponent/TextPlaceholder';
import { MixType } from 'SourceType/Common.type';
import { PriceType } from 'SourceType/Price.type';
import { startMobile } from 'Util/mcMobileCheck';

import './ProductPrice.override.style';

/** @namespace Pwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends PureComponent {
    static propTypes = {
        isSchemaRequired: PropTypes.bool,
        roundedRegularPrice: PropTypes.string,
        priceCurrency: PropTypes.string,
        discountPercentage: PropTypes.number,
        formattedFinalPrice: PropTypes.string,
        formattedSubPrice: PropTypes.string,
        variantsCount: PropTypes.number,
        price: PriceType,
        mix: MixType,
    };

    static defaultProps = {
        isSchemaRequired: false,
        roundedRegularPrice: '0',
        priceCurrency: 'USD',
        discountPercentage: 0,
        formattedFinalPrice: '0',
        formattedSubPrice: null,
        variantsCount: 0,
        mix: {},
        price: {},
    };

    renderCurrentPrice() {
        const {
            discountPercentage,
            formattedFinalPrice,
            reverse,
        } = this.props;
        const priceSchema = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparatment - unsure of resulting type
        // eslint-disable-next-line eqeqeq
        if (formattedFinalPrice == 0) {
            return null;
        }

        const {
            showOverlay, hideOverlay, showPopup, categories, id,
        } = this.props;

        const isADO = categories
            && categories.find((elem) => elem.id === CATEGORY_ID_ANGOLO_DELLE_OCCASIONI) !== undefined;

        const id_popup = `${ID_POPUP}_${id}`;

        return (
            <PriceSemanticElementName
              block="ProductPrice"
              elem="CurrentPrice"
              mods={ { isReverse: reverse } }
            >
                <span { ...priceSchema }>
                    { formattedFinalPrice }
                    <span className="currency">
                        €
                        { isADO && (
                            <button
                              onMouseEnter={ () => {
                                  if (!startMobile()) {
                                      showOverlay(id_popup);
                                  }
                              } }
                              onMouseLeave={ () => {
                                  if (!startMobile()) {
                                      hideOverlay();
                                  }
                              } }
                              onClick={ () => {
                                  if (startMobile()) {
                                      showPopup(id_popup);
                                  }
                              } }
                              className={ `overlayButton ${reverse ? 'isReverse' : ''}` }
                            >
                                <div className="box">
                                    <i aria-hidden="true" className="fa fa-info-circle" />
                                </div>
                                { this.renderPopup() }
                            </button>
                        ) }
                    </span>
                </span>
            </PriceSemanticElementName>
        );
    }

    renderOldPrice() {
        const {
            roundedRegularPrice,
            discountPercentage,
            isSchemaRequired,
            variantsCount,
            reverse,
            price,
        } = this.props;

        const schema = isSchemaRequired && variantsCount > 1 ? { itemProp: 'highPrice' } : {};

        if (discountPercentage === 0) {
            return null;
        }
        const delClass = price.miglior_prezzo === '1' ? 'miglior_prezzo' : '';

        return (
            <del
              block="ProductPrice"
              elem="HighPrice"
              mods={ { isReverse: reverse } }
              aria-label={ __('Old product price') }
              { ...schema }
              className={ delClass }
            >
                { roundedRegularPrice }
                <span className="currency">€</span>
            </del>
        );
    }

    renderPriceByOrder() {
        const {
            promoPosition, price, reverse,
        } = this.props;

        // const delTag = price.miglior_prezzo !== '1' ? 'miglior_prezzo' : '';
        // if (reverse) {
        //     return (
        //         <>
        //             {/* { this.renderOldPrice() } */}
        //             {/* { this.renderOldPrice() } */}
        //             { this.renderCurrentPrice() }
        //         </>
        //     );
        // }

        return (
            <>
                <IfStore storeCode="default">
                    { price.miglior_prezzo !== '1' && !reverse && (
                        <>
                            { this.renderCurrentPrice() }
                            { this.renderOldPrice() }
                        </>
                    ) }

                    { price.miglior_prezzo !== '1' && reverse && (
                        <>
                            { this.renderOldPrice() }
                            { this.renderCurrentPrice() }
                        </>
                    ) }

                    { price.miglior_prezzo === '1' && (
                        <div className={ promoPosition }>
                            { this.renderCurrentPrice() }
                            { this.renderMigliorPrezzo() }
                        </div>
                    ) }
                </IfStore>
                <IfStore storeCode="spain">
                    { this.renderCurrentPrice() }
                    { this.renderOldPrice() }
                </IfStore>
            </>
        );
    }

    renderPopup() {
        const isMobile = startMobile();
        const { id } = this.props;
        const id_popup = `${ID_POPUP}_${id}`;

        if (isMobile) {
            return (
                <Popup
                  id={ id_popup }
                >
                    { __('ADO.price.popup') }
                </Popup>
            );
        }

        return (
            <Overlay
              size="small"
              id={ id_popup }
            >
                { __('ADO.price.popup') }
            </Overlay>
        );
    }

    renderMigliorPrezzo() {
        const {
            price,
        } = this.props;

        if (price.miglior_prezzo === '1') {
            return (
                <img
                  src="/media/promo/388.png"
                  alt="Miglior Prezzo"
                  className="miglior-prezzo-image"
                />
            );
        }

        return null;
    }

    renderPlaceholder() {
        const { mix } = this.props;

        return (
            <p block="ProductPrice" aria-label="Product Price" mix={ mix }>
                <TextPlaceholder mix={ { block: 'ProductPrice', elem: 'Placeholder' } } length="custom" />
            </p>
        );
    }

    getCurrencySchema() {
        const { isSchemaRequired, priceCurrency } = this.props;

        return isSchemaRequired ? { itemProp: 'priceCurrency', content: priceCurrency } : {};
    }

    getCurrentPriceSchema() {
        const { isSchemaRequired, variantsCount, price } = this.props;
        const content_price = price.minimum_price.final_price
            ? price.minimum_price.final_price.value : price.minimum_price.regular_price.value;

        if (variantsCount > 1) {
            return isSchemaRequired ? { itemProp: 'lowPrice', content: content_price } : {};
        }

        return isSchemaRequired ? { itemProp: 'price', content: content_price } : {};
    }

    renderSubPrice() {
        const { formattedSubPrice } = this.props;

        if (!formattedSubPrice) {
            return null;
        }

        return (
            <span
              aria-label={ __('Current product price excl. tax') }
              block="ProductPrice"
              elem="SubPrice"
            >
                { `${ __('Excl. tax:') } ${ formattedSubPrice }` }
            </span>
        );
    }

    renderSchema() {
        const { isSchemaRequired } = this.props;

        if (isSchemaRequired) {
            const currencySchema = this.getCurrencySchema();

            return (
                <meta { ...currencySchema } />
            );
        }

        return null;
    }

    render() {
        const {
            price: {
                minimum_price: {
                    final_price,
                    regular_price,
                } = {},
            } = {},
            discountPercentage,
            formattedFinalPrice,
            mix,
        } = this.props;

        if (!final_price || !regular_price) {
            return this.renderPlaceholder();
        }

        return (
            <>
                <meta itemProp="price" content={ formattedFinalPrice } />
                <meta itemProp="priceCurrency" content="€" />
                <meta
                  itemProp="priceSpecification"
                  content={ JSON.stringify({
                      '@type': 'UnitPriceSpecification',
                      price: formattedFinalPrice,
                      priceCurrency: '€',
                  }) }
                />
                <p
                  block="ProductPrice"
                  mods={ { hasDiscount: discountPercentage !== 0 } }
                  mix={ mix }
                  aria-label={ `Product price: ${formattedFinalPrice}` }
                >
                    { this.renderPriceByOrder() }
                </p>
            </>
        );
    }
}

export default ProductPriceComponent;
