import PropTypes from 'prop-types';
import { PureComponent } from 'react';
import { connect } from 'react-redux';

import { DisplayProductPricesInCatalog } from 'Component/ProductPrice/ProductPrice.config';
import { MixType } from 'SourceType/Common.type';
import { PriceType } from 'SourceType/Price.type';
import { hideActiveOverlay, toggleOverlayByKey } from 'Store/Overlay/Overlay.action';
import { showPopup } from 'Store/Popup/Popup.action';
import {
    calculateFinalPrice,
    formatPrice,
} from 'Util/Price';

import ProductPrice from './ProductPrice.component';
/** @namespace Pwa/Component/ProductPrice/Container/mapStateToProps */
export const mapStateToProps = (state) => ({
    displayTaxInPrice: state.ConfigReducer.priceTaxDisplay?.product_price_display_type,
});

/** @namespace Pwa/Component/ProductPrice/Container/mapDispatchToProps */
export const mapDispatchToProps = (dispatch) => ({
    showOverlay: (id_popup) => dispatch(toggleOverlayByKey(id_popup)),
    hideOverlay: () => dispatch(hideActiveOverlay()),
    showPopup: (id) => dispatch(showPopup(id)),
});

/** @namespace Pwa/Component/ProductPrice/Container/customFormatPrice */
export const customFormatPrice = (price, _currency = 'USD') => {
    const language = navigator.languages ? navigator.languages[0] : navigator.language;

    return new Intl.NumberFormat(
        language,
        { minimumIntegerDigits: 2, maximumFractionDigits: 0, minimumFractionDigits: 0 }
    ).format(price);
};

/** @namespace Pwa/Component/ProductPrice/Container */
export class ProductPriceContainer extends PureComponent {
    static propTypes = {
        isSchemaRequired: PropTypes.bool,
        price: PriceType,
        mix: MixType,
        displayTaxInPrice: PropTypes.string,
    };

    static defaultProps = {
        isSchemaRequired: false,
        displayTaxInPrice: DisplayProductPricesInCatalog.INCL_TAX,
        mix: {},
        price: {},
    };

    getRoundedRegularPrice() {
        const {
            price: {
                minimum_price: {
                    regular_price: {
                        value: regularPriceValue,
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue,
                    } = {},
                } = {},
            } = {},
            displayTaxInPrice,
        } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            return customFormatPrice(regularPriceExclTaxValue);
        }

        return customFormatPrice(regularPriceValue);
    }

    getFormattedFinalPrice() {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage,
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency,
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue,
                    } = {},
                    regular_price: {
                        value: regularPriceValue,
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue,
                    } = {},
                } = {},
            } = {},
            displayTaxInPrice,
        } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.EXCL_TAX) {
            const finalPrice = calculateFinalPrice(
                discountPercentage,
                minimalPriceExclTaxValue,
                regularPriceExclTaxValue
            );

            return customFormatPrice(finalPrice, priceCurrency);
        }

        const finalPrice = calculateFinalPrice(discountPercentage, minimalPriceValue, regularPriceValue);

        return customFormatPrice(finalPrice, priceCurrency);
    }

    containerProps = () => {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage,
                    } = {},
                    final_price: {
                        value: minimalPriceValue,
                        currency: priceCurrency,
                    } = {},
                    regular_price: {
                        value: regularPriceValue,
                    } = {},
                } = {},
            } = {},
        } = this.props;

        if (!minimalPriceValue || !regularPriceValue) {
            return {};
        }

        const roundedRegularPrice = this.getRoundedRegularPrice();
        const formattedFinalPrice = this.getFormattedFinalPrice();
        const formattedSubPrice = this.getFormattedSubPrice();

        return {
            roundedRegularPrice,
            priceCurrency,
            discountPercentage,
            formattedFinalPrice,
            formattedSubPrice,
        };
    };

    getFormattedSubPrice() {
        const {
            price: {
                minimum_price: {
                    discount: {
                        percent_off: discountPercentage,
                    } = {},
                    final_price_excl_tax: {
                        value: minimalPriceExclTaxValue,
                        currency: priceCurrency,
                    } = {},
                    regular_price_excl_tax: {
                        value: regularPriceExclTaxValue,
                    } = {},
                } = {},
            } = {},
            displayTaxInPrice,
        } = this.props;

        if (displayTaxInPrice === DisplayProductPricesInCatalog.BOTH) {
            const finalPrice = calculateFinalPrice(
                discountPercentage,
                minimalPriceExclTaxValue,
                regularPriceExclTaxValue
            );

            return formatPrice(finalPrice, priceCurrency);
        }

        return null;
    }

    render() {
        return (
            <ProductPrice
              { ...this.props }
              { ...this.containerProps() }
            />
        );
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(ProductPriceContainer);
