import { isSignedIn } from '@scandipwa/scandipwa/src/util/Auth/IsSignedIn';
import BrowserDatabase from '@scandipwa/scandipwa/src/util/BrowserDatabase/BrowserDatabase';
import Cookies from 'js-cookie';

export const GUEST_QUOTE_ID = 'guest_quote_id';
export const GUEST_QUOTE_ID_COOKIE_NAME = 'gqid';
export const CART_ID = 'cart_id';

/** @namespace Pwa/Util/Cart/Token/setGuestQuoteId */
export const setGuestQuoteId = (token) => {
    // console.log(GUEST_QUOTE_ID_COOKIE_NAME, token, "Cookies")

    BrowserDatabase.setItem({
        token,
        isCustomerToken: isSignedIn(),
    }, GUEST_QUOTE_ID);

    // const {
    //     token,
    //     isCustomerToken
    // } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};
    Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
};

/** @namespace Pwa/Util/Cart/Token/getGuestQuoteId */
export const getGuestQuoteId = () => {
    const {
        token,
        isCustomerToken,
    } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

    if (isCustomerToken && !isSignedIn()) {
        BrowserDatabase.deleteItem(GUEST_QUOTE_ID);

        return null;
    }

    Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });

    return token;
};

/** @namespace Pwa/Util/Cart/Token/deleteGuestQuoteId */
export const deleteGuestQuoteId = () => {
    BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
    Cookies.remove(GUEST_QUOTE_ID_COOKIE_NAME);
};

/** @namespace Pwa/Util/Cart/Token/refreshCookie */
export const refreshCookie = () => {
    const {
        token,
        // isCustomerToken,
    } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || '';
    const cookieValue = Cookies.get(GUEST_QUOTE_ID_COOKIE_NAME);

    // console.log("refreshCookie");
    if (token !== cookieValue) {
        Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
    }
};

/** @namespace Pwa/Util/Cart/Token/setCartId */
export const setCartId = (token) => {
    BrowserDatabase.setItem({
        token,
        isCustomerToken: isSignedIn(),
    }, GUEST_QUOTE_ID);

    Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
};

/** @namespace Pwa/Util/Cart/Token/deleteCartId */
export const deleteCartId = () => {
    let {
        token,
        // isCustomerToken,
    } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

    token = undefined;
    BrowserDatabase.setItem(token, GUEST_QUOTE_ID);
};

/** @namespace Pwa/Util/Cart/Token/getCartId */
export const getCartId = () => {
    const {
        token,
        isCustomerToken,
    } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

    if (token) {
        if (isCustomerToken && !isSignedIn()) {
            return null;
        }

        Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });

        return token;
    }

    return null;
};

// /** @namespace Util/Cart/Token/setCartId */
// export const setCartId = (token) => {
//     //const { website_code } = window;
//     console.log(token, "token");

//     BrowserDatabase.setItem({
//         token,
//         isCustomerToken: isSignedIn()
//     }, GUEST_QUOTE_ID);

//     Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
// };

// /** @namespace Util/Cart/Token/deleteCartId */
// export const deleteCartId = () => {
//     const {
//         token,
//         isCustomerToken
//     } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};
//     Cookies.remove(GUEST_QUOTE_ID_COOKIE_NAME);
//     //tokens[website_code] = undefined;
//     BrowserDatabase.setItem( {
//         token:undefined,
//         isCustomerToken :undefined
//     }, GUEST_QUOTE_ID);
// };

// /** @namespace Util/Cart/Token/getCartId */
// export const getCartId = () => {
//     //  const { website_code } = window;

//     const {
//         token,
//         isCustomerToken
//     } = BrowserDatabase.getItem(GUEST_QUOTE_ID) || {};

//     if (isCustomerToken && !isSignedIn()) {
//         BrowserDatabase.deleteItem(GUEST_QUOTE_ID);
//         return null;
//     }
//     console.log("old-cookie", token);

//     if (token) {
//         if (isCustomerToken && !isSignedIn()) {
//             return null;
//         }

//         Cookies.set(GUEST_QUOTE_ID_COOKIE_NAME, token, { expires: 365 });
//         return token.token;
//     }

//     return null;
// };
